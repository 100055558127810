<!-- // 售后商品信息组件 @ljp-2021/1/4 -->
<template>
    <!-- 退款商品信息 start -->
    <div style="border-bottom: 1px solid #dddddd;">
        <div class="sld_refund_goods flex_row_between_start">
            <div class="refund_goods_left flex_row_start_center">
                <div class="refund_goods_img" :style="{backgroundImage:'url(' + refundOrderDetail.goodsImage + ')'}"></div>
                <div style="width: 100%;">
                    <div class="refund_goods_des">
                        <p>{{refundOrderDetail.goodsNameJoin}}</p>
                        <p>{{refundOrderDetail.specValues}}</p>
                    </div>
                    <div class="flex_row_between_center">
                        <p style="margin-left: 20px;">单价：¥{{ refundOrderDetail.goodsPrice }}</p>
                        <p>
                            <p style="width: 100px;">下单数量：{{ refundOrderDetail.goodsNum }}</p>
                            <p style="width: 100px;margin-top: 10px;" v-if="refundOrderDetail.firstCategoryId==12">收货数量：{{ refundOrderDetail.confirmTonnageNum }}</p>
                        </p>
                    </div>
                </div>
            </div>
            <!-- <div class="refund_goods_num">*{{refundOrderDetail.goodsPrice || refundOrderDetail.productNum}}</div> -->
        </div>
        
        <div class="refund_footer flex_row_start_center">
            <div class="flex_row_start_center">
                <p class="flex_row_start_center">
                    <span style="display: inline-block; width: 100px;">退款金额：</span>
                    <span v-show="isShow">¥{{refundOrderDetail.goodsSumPrice}}</span>
                    <el-input v-show="!isShow" size="small" autofocus v-model="refundOrderDetail.goodsSumPrice" 
                    :validate-event="true"
                    
                    placeholder="请输入退款金额" @blur="refundBlur"/>
                </p>
                <p v-show="isShow" @click="editAmount">修改金额</p>
            </div>
            <div>申请数量：{{refundOrderDetail.confirmTonnageNum>0?refundOrderDetail.confirmTonnageNum:refundOrderDetail.goodsNum}}</div>
            
          
        </div>
        
    </div>
   
    <!-- 退款商品信息 end -->
</template>

<script>
import CheckrdImg from "../assets/iconImages/Checked@2x.png";
import ellipse from "../assets/iconImages/ellipse@2x.png";
import { reactive, getCurrentInstance, ref, onMounted, watchEffect } from "vue";
import { apiUrl, apiUrlSub } from "../utils/config";
export default {
  //  props: {
  //    refundOrderDetail: {
  //      type: Object,
  //      value: {},
  //    },
  //    AfterSaleType: {
  //      type: String,
  //      value: {},
  //    },

  //  },
  props: ["refundOrderDetail","isChecked" ,"AfterSaleType","onCheck","submitOBj","itemTableData"],
  setup(props, { emit }) {
    const isShow = ref(true);
    //const isChecked = ref(props.isChecked);
    
    const editAmount = () => {
      isShow.value = false;
    };
    const refundBlur = () => {
      isShow.value = true;
    };
    
    return {
      isShow,
      editAmount,
      refundBlur,
      
    };
  },
};
</script>

<style lang="scss" >
.clickBtn {
  color: $colorMain;
  cursor: pointer;
}
.sld_refund_goods {
  padding: 20px;
  .refund_goods_left {
    width: 100%;
    .refund_goods_img {
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .refund_goods_des {
      margin-left: 20px;
      p:nth-child(1) {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 444px;
        margin-top: 8px;
        margin-bottom: 20px;
      }
      p:nth-chid(2) {
        width: 444px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 19px;
      }
    }
  }
  .refund_goods_num {
    width: 100px;
    text-align: center;
    line-height: 100px;
    font-size: 12px;
  }
}
.refund_footer {
  padding: 0px 20px 10px 20px;
  div:first-child {
    width: 300px;
    p:first-child {
      width: 200px;
    }
    p:last-child {
      cursor: pointer;
      padding: 3px 6px;
      border-radius: 20px;
      background-color: rgb(22, 155, 213);
      color: #ffffff;
    }
  }
}
</style>